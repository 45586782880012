// var tabText = function () {



//     $(".tab-pane").each(function () {
//         var images = $(this).find(".image_container");

//         if (images.length > 0) {
//             var img_01 = images[0];
//             var img_02 = images[1];

//             var img_01_height = $(img_01).height();
//             var img_02_height = $(img_02).height();
//             var text_height = img_01_height - img_02_height;

//             $(this).find(".ce_text")
//                 .height(text_height)
//                 .addClass('d-flex align-items-center')
//                 .wrapInner('<div class="inner">');
//         }

//     });

// };

var tabLayout = function () {
    
    $('.nav-tabs').addClass('nav-fill');
    $('#headline-tab').addClass('disabled').wrapInner('<h2>');


    $('.tab-pane').wrapInner('<div class="container-fluid p-0"><div class="row no-gutters">');
    $('.tab-pane .ce_text').wrapInner('<div class="inner w-100">');

    $(".tab-pane").each(function () {

        var ce_image = $(this).find(".ce_image");
        var ce_text = $(this).find(".ce_text");

        if (ce_image.length > 0) {
            var img_01 = ce_image[0];
            var img_02 = ce_image[1];

            $(img_02).next('.ce_text').addBack().wrapAll('<div class="col-lg-5 flex-column">');

            $('.tab-pane .ce_text').addClass('bg-primary d-flex align-content-center flex-wrap');
        }
    });
    $('#unsere-leistungen-tabs .nav-tabs li:nth-child(2) a').tab('show');
}

var tabHeight = function() {
    var activeTab = $('.tab-pane.active');
    var images = $(activeTab).find(".ce_image img");
    var ce_text = $(activeTab).find(".ce_text");

    if (images.length > 0) {
        var img_01 = images[0];
        var img_02 = images[1];

        var img_01_height = $(img_01).height();
        var img_02_height = $(img_02).height();
        var text_height = img_01_height - img_02_height;

        if($(window).width() > 991) {
            $(ce_text).height(text_height);
        } else {
            $(ce_text).height('auto');
        }
    }
}

var navigation = function () {

    // $("#mainNav a").on('click', function (event) {
    $('#mainNav a').click(function (event) {

        $('.navbar-collapse').removeClass('show');

        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            offset = 120;

            $('html, body').animate({
                scrollTop: ($(hash).offset().top - offset)
            }, 800, function () {
                // window.location.hash = hash;
            });
        }
    });
}


$(document).ready(function () {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        tabHeight();
    })
    tabLayout();
    if ($('body').hasClass('index')) {
        navigation();
    }
});

$(window).resize(function() {
    tabHeight();
})